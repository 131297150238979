/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {graphql} from 'gatsby'
import {Layout, Pagination, PostCard} from '../components/blog'
import {MetaData} from '../components/blog/meta'

type Props = {
  data: {
    allGhostPost: any
  }
  location: {
    pathname: string
  }
  pageContext: any
}

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/site-config.js under `postsPerPage`.
 *
 */
const Index = ({data, location, pageContext}: Props) => {
  const posts = data.allGhostPost.edges

  return (
    <>
      <MetaData location={location} />
      <Layout isBlogIndex={true}>
        <div className="container">
          <section className="post-feed">
            {posts.map(({node}: any) => (
              // The tag below includes the markup for each post - components/blog/post-card.tsx
              <PostCard key={node.id} post={node} />
            ))}
          </section>
          <Pagination pageContext={pageContext} />
        </div>
      </Layout>
    </>
  )
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: {order: DESC, fields: [published_at]}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
